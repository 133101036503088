import {
  CostFormWithTaxAssist,
} from './forms/index';
import React from 'react';

export const reports = [
  {
    name: 'MPC Authorizations',
    link: 'mpcauthorizations',
    tableauReport: true,
    description: 'A list of all authorization within a timeframe with additional filters on Departure State, Departure Country, Destination State, Destination Country, Or Employee Type.',
    icon: 'house-leave',
    reportPath: 'views/MPCAuthorizationsReport{{env}}/Dashboard1',
    form: null,
    backgroundColor: 'linear-gradient(45deg,#4D7025,#436322)',
    reportFileName: null,
    permissionName: 'reports:mpcAuthorization',
  },
  {
    name: 'MPC Average Cost',
    link: 'mpcaveragecost',
    tableauReport: true,
    description: 'Provides average cost for relocations completed during the selected timeframe.  Displays by policy and by benefit category.',
    icon: 'chart-line',
    reportPath: 'views/MPCAverageCost{{env}}/MPCAverageCost',
    form: null,
    backgroundColor: 'linear-gradient(45deg,#67a783,#4d8766)',
    reportFileName: null,
    permissionName: 'reports:mpcAverageCost',
  },
  {
    name: 'MPC Cost',
    link: 'mpccost',
    tableauReport: true,
    description: 'A list of expenses between a timeframe with additional filters of employee type, resource, or status.',
    icon: 'sack-dollar',
    reportPath: 'views/MPCCost{{env}}/Dashboard',
    form: null,
    backgroundColor: 'linear-gradient(45deg,#4D7025,#436322)',
    reportFileName: null,
    permissionName: 'reports:mpcCost',
  },
  {
    name: 'MPC Diversity Information',
    link: 'mpcdiversityreport',
    tableauReport: true,
    description: 'Authorizations by date, showing diversity information.',
    icon: 'user-friends',
    reportPath: 'views/MPCDiversityInformation{{env}}/MPCDiversityInformation',
    form: null,
    backgroundColor: 'linear-gradient(45deg,#fd6120,#ef5f2f)',
    reportFileName: null,
    permissionName: 'reports:mpcAuthDiversityReport',
  },
  {
    name: 'MPC Transferee Expenses',
    link: 'mpctransfereeexpense',
    reportPath: 'views/MPCTransfereeExpenses{{env}}/SelectMPCTransferee',
    form: null,
    description: 'A list of costs associated with an authorization.',
    icon: 'hand-holding-usd',
    backgroundColor: 'linear-gradient(45deg,#4D7025,#436322)',
    reportFileName: null,
    permissionName: 'reports:mpcTransfereeExpense',
    tableauReport: true,
  },
  {
    name: 'Authorizations',
    link: 'authorizations',
    description: 'A list of all authorization within a timeframe with additional filters on Departure State, Departure Country, Destination State, Destination Country, Or Employee Type.',
    // image: require('../../images/300x200.png'),
    icon: 'house-leave',
    reportPath: 'views/AuthorizationsOnly{{env}}/Dashboard',
    backgroundColor: 'linear-gradient(45deg,#67a783,#4d8766)',
    tableauReport: true,
    reportFileName: null,
  },
  { //Added via AB#1315 - Komatsu Client Only Report for "Expats Only Authorizations Report"
    name: 'Authorizations - Expats Only',
    link: 'komatsuexpatinforeport',
    tableauReport: true,
    description: 'A list of all expat authorizations, including proposed start and end dates.',
    icon: 'house-leave',
    reportPath: 'views/Komatsu-ExpatInformationReport{{env}}/Dashboard1',
    form: null,
    backgroundColor: 'linear-gradient(45deg,#4D7025,#436322)',
    reportFileName: null,
    permissionName: 'reports:komatsuExpatInfoReport',
  },
  {
    name: 'Closed Property Detail',
    link: 'closedproperty',
    description: 'A list of properties that have been closed between a timeframe and disposition type.',
    // image: require('../../images/300x200.png'),
    icon: 'sign',
    reportPath: 'views/ClosedPropertiesDetail{{env}}/Dashboard',
    form: null,
    backgroundColor: 'linear-gradient(45deg,#224d88,#4182ca)',
    tableauReport: true,
    reportFileName: null,
  },
  {
    name: 'Policy Exceptions',
    link: 'policyexceptions',
    description: 'A list of exceptions between a timeframe with additional filters of employee type.',
    // image: require('../../images/300x200.png'),
    icon: 'file-contract',
    reportPath: 'views/PolicyExceptions{{env}}/Dashboard',
    form: null,
    backgroundColor: 'linear-gradient(45deg,#fd6120,#ef5f2f)',
    reportFileName: null,
    tableauReport: true,
  },
  {
    name: 'Cost',
    tableauReport: true,
    link: 'costreport',
    description: 'A list of expenses between a timeframe with additional filters of employee type, resource, or status.',
    // image: require('../../images/300x200.png'),
    icon: 'sack-dollar',
    reportPath: 'views/Cost{{env}}/Dashboard',
    form: null,
    backgroundColor: 'linear-gradient(45deg,#BA8A00,#F0B11D)',
    reportFileName: null,
    permissionName: 'expenses:read',
    showComingSoon: true, // if unauthorized, show coming soon instead of hide card entirely
  },
  {
    // some, but not all clients and/or divisions will have this report
    // restrict by permission
    name: 'Cost with Tax Assistance',
    link: 'costreporttax',
    description: 'A list of expenses between a timeframe with additional filters of employee type, resource, or status.',
    // image: require('../../images/300x200.png'),
    icon: 'sack-dollar',
    reportPath: '/TRCClientReports/rptCostTaxAssistance',
    form: <CostFormWithTaxAssist />,
    backgroundColor: 'linear-gradient(45deg,#7B51FF,#493293)',
    reportFileName: 'CostTaxAssistance',
    permissionName: 'reports:costTaxAssistance',
  },
  {
    name: 'Service',
    tableauReport: true,
    link: 'evalbytransferee',
    description: 'Provide a detailed report by transferee of the service survey scores.',
    // image: require('../../images/300x200.png'),
    icon: 'poll',
    reportPath: 'views/ServiceEvaluation{{env}}/Dashboard',
    form: null,
    backgroundColor: 'linear-gradient(45deg,#80FFF6,#63C4BC)',
    reportFileName: null,
  },
  {
    name: 'Active Properties',
    link: 'activeproperties',
    description: 'A list of active Home Sales that have been listed between a timeframe and disposition type.',
    // image: require('../../images/300x200.png'),
    icon: 'sign',
    reportPath: 'views/ActiveProperties{{env}}/Dashboard',
    backgroundColor: 'linear-gradient(45deg,#f4414f,#f441a9)',
    reportFileName: null,
    form: null,
    tableauReport: true,
  },
  {
    name: 'Transferee Expenses',
    link: 'transfereeexpense',
    reportPath: 'views/TransfereeExpenseReport{{env}}/Dashboard1',
    form: null,
    description: 'A list of costs associated with an authorization.',
    icon: 'hand-holding-usd',
    backgroundColor: 'linear-gradient(45deg,#4D7025,#436322)',
    reportFileName: null,
    permissionName: 'reports:transfereeExpenses',
    tableauReport: true,
  },
  {
    name: 'Valero Cost Report',
    link: 'valeroexpenses',
    reportPath: 'views/ValeroCost{{env}}/Dashboard',
    form: null,
    description: 'A list of expenses between a selected timeframe with additional filters of File Status, Relocation Program, and Employee Type',
    backgroundColor: 'linear-gradient(45deg,#4D7025,#436322)',
    reportFileName: null,
    permissionName: 'reports:valeroCostReport',
    tableauReport: true,
  },
  {
    name: 'Expense Detail Report',
    link: 'hpClientExpenseDetails',
    reportPath: 'views/ClientExpenseDetail-HP{{env}}/ExpenseDetail',
    form: null,
    description: 'A detailed list of expenses (by transferee) between a selected timeframe.',
    backgroundColor: 'linear-gradient(45deg,#4D7025,#436322)',
    reportFileName: null,
    permissionName: 'reports:hpExpenseDetailReport',
    tableauReport: true,
  },
];

export const getReportByLink = (link) => {
  return reports.find((report) => {
    return report.link === link;
  });
};

export default { reports, getReportByLink };
